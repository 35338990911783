import React from 'react';
import {Link} from 'gatsby';
import CatoImg from '../cato-image';
import './style.scss';

const Customer = ({title, desc, path, logo}) => {

    return (
        <Link to={path || '/'} className={'customer'}>
            {logo && <div className="img-wrapper">
                <CatoImg img={logo} />
            </div>}
            {/*<div className={'color-block'}/>*/}
            {title && <h3 dangerouslySetInnerHTML={{__html: title}}/>}
            <hr />
            {desc && <h4>{desc}</h4>}
            <hr />
            {/*<Link to={'/'}>Secure and Optimized SD-WAN</Link>*/}
            {/*<Link to={'/'}>Appliance Elimination</Link>*/}
            {/*<Link to={'/'}>Affordable MPLS Alternative</Link>*/}
        </Link>
    )
}

export default Customer;