import React, {Component} from 'react';
import {graphql} from 'gatsby';
import CatoImg from '../components/cato-image';
import CustomerSideQuote from '../components/customer-side-quote';
import Layout from '../components/layout';
import Customer from "../components/customer";
import PromoFooter from "../components/promo-footer-banner";
import {IS_MOBILE} from "../common/consts";
import {mapGartner, mapStickyBanner, mapResourcesList} from '../common/utils';
import CatoButton from '../components/cato-button';
import VideoWithLightbox from '../components/video-lightbox';
import VideoLightboxMapper from '../components/video-lightbox/mapper';
import '../assets/styles/pages/_customer-inner.scss';
import '../assets/styles/responsive/pages/customer-inner-small-desktop.scss';
import '../assets/styles/responsive/pages/customer-inner-mobile.scss';

const handleIframeHeight = () => {
    const iframes = Array.from(document.querySelectorAll('iframe[src*=youtube]'))
    iframes.forEach(iframe => {

        const parentWidth = iframe.parentNode.getBoundingClientRect().width;
        iframe.setAttribute('width', parentWidth);
        iframe.setAttribute('height', parentWidth / 1.8);
    })
}


class CustomerInnerPage extends Component {

    constructor(props){
        super(props)

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true})
        }

        if(Array.from(document.querySelectorAll('iframe[src*=youtube]')).length > 0) {
            window.addEventListener('resize', handleIframeHeight)

            handleIframeHeight()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', handleIframeHeight)
    }

    render() {

        const {data} = this.props;
        const {wpPage} = data;
        const {customerCompanyDescriptionParent, customerInnerHeaderWithQuoteParent, moreCustomerStoriesParent, videoLightboxParent, resourcesBannerRows} = wpPage;
        const resourceData = resourcesBannerRows && resourcesBannerRows.resourcesList && resourcesBannerRows.resourcesList.length ? mapResourcesList(resourcesBannerRows.resourcesList) : null;

        const stickyBanner = mapStickyBanner(wpPage)

        const gartner = mapGartner(wpPage);

        const breadcrumbs = wpPage.parent ?
            [{title: wpPage.parent.pageSeoTitle.pageSeoTitle, path: wpPage.parent.path},{title: wpPage.title, path: this.props.location.pathname}]
            :
            [{title: wpPage.title, path: this.props.location.pathname}]

        const video = videoLightboxParent.videoLightbooxCode ? VideoLightboxMapper(videoLightboxParent) : null;

        const companyDescription =
            customerCompanyDescriptionParent.customerCompanyDescription
                ?
                `${customerCompanyDescriptionParent.customerCompanyDescriptionHeadline
                    ?
                    '<h2>' + customerCompanyDescriptionParent.customerCompanyDescriptionHeadline + '</h2>'
                    :
                    '<h2>About Us</h2>'}${customerCompanyDescriptionParent.customerCompanyDescription}`
                :
                null;

        return (
            <Layout
                className="customer-inner-page"
                lang={this.props.pageContext.lang}
                whiteMenu={true}
                yoastData={wpPage.seo || null}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                title={wpPage.title}
                gartner={gartner}
                seoPath={this.props.location.pathname}
                translations={this.props.pageContext.translations}
                breadcrumbs={breadcrumbs}
                wpid={wpPage.databaseId}
                {...{stickyBanner}}>
                 <section className={'section-top'}>
                     <div className={'wrapper'}>
                         <div className={'left'}>
                             <div className="image-span-wrap">
                             {customerInnerHeaderWithQuoteParent.customerCustomerLogo &&
                            <div className="img-wrapper">
                                <CatoImg alt={'Customer Company Logo'} img={customerInnerHeaderWithQuoteParent.customerCustomerLogo} />
                            </div>}
                             {customerInnerHeaderWithQuoteParent.customerIndustryType && <span>{customerInnerHeaderWithQuoteParent.customerIndustryType}</span>}
                             </div>
                             <div className="headline-pills-wrapper">
                                 {customerInnerHeaderWithQuoteParent.customerInnerHeading && <h1>{customerInnerHeaderWithQuoteParent.customerInnerHeading}</h1>}
                                 {customerInnerHeaderWithQuoteParent.customerUseCases &&
                                <div className={'pills-wrapper'}>
                                    {
                                        customerInnerHeaderWithQuoteParent.customerUseCases
                                            .sort((a, b) => parseInt(a.customerInnerUseCaseOrder, 10) - parseInt(b.customerInnerUseCaseOrder, 10))
                                            .map((item, i) => <div key={`pill-${i}`} className="pill">{item.customerInnerUseCase}</div>)
                                    }
                                </div>
                                }
                             </div>
                             <article className="section-main">
                                 {video &&
                                <VideoWithLightbox
                                    items={[video]}
                                />
                                }
                                 {wpPage.content && <div dangerouslySetInnerHTML={{__html: wpPage.content}}/>}
                             </article>
                         </div>
                         <div className={'right'}>
                             {customerInnerHeaderWithQuoteParent.customerCustomerQuote &&
                                <CustomerSideQuote
                                    quote={customerInnerHeaderWithQuoteParent.customerCustomerQuote}
                                    customerImg={customerInnerHeaderWithQuoteParent.customerCustomerImage ? customerInnerHeaderWithQuoteParent.customerCustomerImage : null}
                                    name={customerInnerHeaderWithQuoteParent.customerSourceName || ""}
                                    position={customerInnerHeaderWithQuoteParent.customerCustomerPosition || ""}
                                    className={'leftSeparator'}
                                />
                            }
                             {companyDescription &&
                            <div className={'leftSeparator customer-description'} dangerouslySetInnerHTML={{__html: companyDescription}}/>}
                             <div className={'leftSeparator more-customer-stories'}>
                                 {moreCustomerStoriesParent.moreCustomerStories && <h2>{moreCustomerStoriesParent.moreCustomerStories}</h2>}
                                 {data.allWpPage &&
                                data.allWpPage.edges &&
                                data.allWpPage.edges.map((item, i) => {
                                    const {node} = item;
                                    const img = node.customerInnerHeaderWithQuoteParent &&
                                    node.customerInnerHeaderWithQuoteParent.customerCustomerLogo
                                        ?
                                        this.state.isMobile
                                            ?
                                            node.customerInnerHeaderWithQuoteParent.customerLogoMobile
                                            :
                                            node.customerInnerHeaderWithQuoteParent.customerCustomerLogo
                                        :
                                        null;
                                    return (
                                        <Customer
                                            path={node.postInternalUrl && node.postInternalUrl.postUrl ? node.postInternalUrl.postUrl : null}
                                            title={node.customerInnerHeading && node.customerInnerHeading.customerInnerLobbyPageHeading
                                                ?
                                                node.customerInnerHeading.customerInnerLobbyPageHeading
                                                :
                                                node.title
                                            }
                                            desc={node.customerInnerHeaderWithQuoteParent && node.customerInnerHeaderWithQuoteParent.customerIndustryType
                                                ?
                                                node.customerInnerHeaderWithQuoteParent.customerIndustryType
                                                :
                                                null}
                                            logo={img}
                                        />
                                    )
                                })
                                }
                                 <CatoButton path="/customers" className="text-bg oval pill" type={'link'} label="ALL STORIES"/>
                             </div>
                         </div>
                     </div>
                 </section>
                 {resourceData && <PromoFooter data={resourceData} />}
             </Layout>
         )
     }
 }
 export default CustomerInnerPage;

export const pageQuery = graphql`
   query customerInnerPageQuery($id: String!, $lang: WpLanguageCodeEnum!) {
        wpPage(id: { eq: $id }) {
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            parent {
                ... on WpPage {
                    id
                    path: uri
                    pageSeoTitle: pageSeoTitle {
                        pageSeoTitle
                    }
                }
            }
            ...YoastData
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            customerInnerHeaderWithQuoteParent {
                customerIndustryType
                customerCustomerLogo {
                    altText
                    localFile {
                        childImageSharp {
                            fixed(height: 47){
                                ...GatsbyImageSharpFixed_noBase64
                            }
                        }
                        extension
                        publicURL
                    }
                }
                customerInnerHeading
                customerCustomerQuote
                customerCustomerImage {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 50){
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                        publicURL
                    }
                }
                customerSourceName
                customerCustomerPosition
                customerUseCases {
                    customerInnerUseCase
                    customerInnerUseCaseOrder
                }
            }
            customerCompanyDescriptionParent {
                customerCompanyDescriptionHeadline
                customerCompanyDescription
            }
            moreCustomerStoriesParent {
                moreCustomerStories
                customerStoriesButtonText
            }
            ...VideoLightbox
            ...StickyBanner
            ...GartnerStrip
            ...ResourcesBannerRows
        }
        allWpPage(
            filter: {templateFileName: {eq: "customer-inner.php"}, id: {ne: $id}, status: {eq: "publish"}, language: { code: {eq: $lang}}}
            sort: {fields: date, order: DESC}
            limit: 5
        ){
            edges{
                node{
                    id
                    title
                    customerInnerHeaderWithQuoteParent {
                        customerIndustryType
                        customerCustomerLogo {
                            altText
                            localFile {
                                childImageSharp {
                                    fixed(height: 45) {
                                        ...GatsbyImageSharpFixed_noBase64
                                    }
                                }
                                extension
                                publicURL
                            }
                        }
                        customerLogoMobile: customerCustomerLogo {
                            altText
                            localFile {
                                childImageSharp {
                                    fixed(height: 27) {
                                        ...GatsbyImageSharpFixed_noBase64
                                    }
                                }
                                extension
                                publicURL
                            }
                        }
                        customerInnerHeading  
                    }
                    postInternalUrl {
                        postUrl
                    }
                }
            }
        }
    }`;