import React from 'react';
import quotes from '../../assets/images/quotes dark.svg';
import CatoImg from '../cato-image';
import './style.scss';


const CustomerSideQuote = ({customerImg, quote, name, position, className='', logoWithName, companyLogo}) => {
    return (
        <div className={`quote customer-side-quote-container ${className}`}>
            <img src={quotes} alt={'quotes'}/>
            <div className="quote-content" dangerouslySetInnerHTML={{__html: quote}} />
            <div className={'name-tag'}>
                {customerImg &&
                <CatoImg
                    className="customer-icon-image"
                    img={customerImg} />
                }
                <div>
                    {name && <span>{name},</span>}
                    {position && <span>{position}</span>}
                </div>
                {logoWithName && companyLogo &&
                    <div className="company-logo">
                        <CatoImg img={companyLogo} />
                    </div>
                }
            </div>
        </div>
    )
};

export default CustomerSideQuote;