
const VideoLightboxMapper = (item) => (
    {
        vidId: item.videoLightbooxCode || '',
        vidImg: item.videoLightboxVideoImage && item.videoLightboxVideoImage.localFile ? item.videoLightboxVideoImage : null,
        title: item.videoLightboxTitle || '',
        subTitle: item.videoLightboxSubtitle || '',
        description: item.videoLightboxDescription || '',
        path: item.videoLightboxButtonLink || '',
        lightboxButtonLabel: item.videoLightboxButtonLabel || ''
    }
)

export default VideoLightboxMapper